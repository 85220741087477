import {createStore} from 'vuex'
import axios from "axios"
import * as langUtil from '../utils/langUtil'
import {Langs} from "../utils/langUtil";
import Cookie from 'js-cookie'
export interface StoreOption {
  currentLang: langUtil.Langs,
  token: string
}

const getSettingLang = () => {
  const langInSession =  Cookie.get('LANG')?.split('-')[0]?.toLowerCase()
  if (langInSession === Langs.zh) {
    return Langs.zh
  }
  if (langInSession === Langs.en) {
    return Langs.en
  }
  return langUtil.getUserLang()
}
export default createStore<StoreOption>({
  state: {
    currentLang: getSettingLang(),// 当前语言言
    // 没有使用 i18n 直接切换不同语言文字的方案。
    // 因为设计时没考虑多语言直接切换布局，直接替换语言排版会不符合要求，还要再调折行或样式。
    // 多语言直接切换整块儿组件（有几种语言，就复制几个组件），不同语言的组件在代码互相独立，修改样式互不不干扰。
    token: '',
  },
  mutations: {
    setCurrentLang(state, currentLang) {
      Cookie.set('LANG', currentLang)
      state.currentLang = currentLang
    },
    setToken(state, token) {
      state.token = token
    },
  },
  getters: {
    token: (state) => state.token,
    currentLang: (state) => state.currentLang,
  },
  actions: {
    update_token: async ({commit}) => {
      const {data: res}: any = await axios.get(`${process.env.NODE_ENV === 'development' ? '/proxy-prefix/token' : '/token'}`)
      commit('setToken', res?.data)
    }
  },
  modules: {}
})
