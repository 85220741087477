// PC端网页最小宽度、移动端显示宽度1200
// 移动最小尺寸端按屏幕像素
export const siteDesignWidth = 1920 //px // 设计图标准宽度
export const pxAndRemRate = 10
export const pcMinWidth = 1200

export const isMobile = () => {
  const info = navigator.userAgent;
  const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
  for (let i = 0; i < agents.length; i++) {
    if (info.indexOf(agents[i]) >= 0) {
      return true;
    }
  }
  return false;
}
export const siteMinWidth = () => isMobile() ? document.documentElement.clientWidth * window.devicePixelRatio : pcMinWidth  //px

export const siteBaseWidth = () => { //px // 窗口当前大小
  if (isMobile()) {
    return document.documentElement.clientWidth * window.devicePixelRatio
  } else {
    return Math.max(document.documentElement.clientWidth, pcMinWidth)
  }
}
export const getScaleRate = () => {
  // PC端：
  // 窗口为1920px宽时；  1rem = 10px
  // 参考设计图尺寸时，标准宽度设为1920px时；  1rem = 10px
  // 移动端：
  // 设计图参考宽度为1920px时；  1rem = 10px
  return siteBaseWidth() / siteDesignWidth * pxAndRemRate
}

// 计算指定块是否需要显示的方法。（块顶部位置高出窗口底部的距离，大于startShowBottom时，为true，显示）
export const showFadeBlock = (fadeBlock?: any, startShowBottom?: number,) => {
  if (fadeBlock) {
    if (fadeBlock.$el) {
      return fadeBlock.$el.getBoundingClientRect().top < window.innerHeight + 100 - (startShowBottom || 0)
    }
    return fadeBlock.getBoundingClientRect().top < window.innerHeight + 100 - (startShowBottom || 0)
  } else {
    return false
  }
}

// 让指定块在底端渐现的方法。（块顶部位置高出窗口底部的距离，大于startShowBottom时，当前传入的ref对应节点，显示）
export const showSelfFadeBlock = (fadeBlock?: any, startShowBottom?: number, delay?: number, customClass?: string) => {
  customClass = customClass || 'fade-block--show'
  if (fadeBlock) {
    const el = fadeBlock.$el ? fadeBlock.$el : fadeBlock
    const classes = el.getAttribute("class")?.split(' ')?.filter((e: string) => !!e && e !== 'fade-block') || []
    classes.push('fade-block')
    el.setAttribute("class", classes.join(" "))
    setTimeout(() => {
      if (el.getBoundingClientRect().top < window.innerHeight + 100 - (startShowBottom || 0)) {
        const classes = el.getAttribute("class")?.split(' ')?.filter((e: string) => !!e && e !== customClass) || []
        classes.push(customClass)
        el.setAttribute("class", classes.join(" "))
      } else {
        const classes = el.getAttribute("class")?.split(' ')?.filter((e: string) => !!e && e !== customClass) || []
        el.setAttribute("class", classes.join(" "))
      }
    }, delay || 10)
  }
}

export const getFloatY = (floatBlock?: HTMLDivElement, maxFloatY?: number) => {
  if (floatBlock) {
    return -(maxFloatY || 0) * (1 - (floatBlock.getBoundingClientRect().top + 300) / (window.innerHeight / 2))
  } else {
    return 0
  }
}
// 辅助静态化页面时，补全跳转url前缀
export const linkHost = process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_LINK_HOST


//
// const softScroll=()=>{
//   // ----------------------------自定义滑轮柔和滚动方法----------------------------
//   let speed = 0
//   const resistance = 1.5
//   // eslint-disable-next-line no-undef
//   let scrollTimer: NodeJS.Timeout | null = null
//   // 阻止原生滚动事件，用js实现滚动效果
//   const scrollHandler = (evt: any) => {
//     speed = Math.min(Math.abs(evt.deltaY), 30) * SiteUtil.getScaleRate()
//     if (scrollTimer) {
//       clearInterval(scrollTimer)
//       scrollTimer = null
//     }
//     scrollTimer = setInterval(() => {
//       window.scrollTo({top: window.scrollY + (String(evt.deltaY).startsWith('-') ? -1 : 1) * speed})
//       speed = Math.max(0, speed - resistance)
//       if (speed === 0 && scrollTimer) {
//         clearInterval(scrollTimer)
//       }
//     }, 13)
//     evt = evt || window.event;
//     if (evt.preventDefault) { // Firefox
//       evt.preventDefault();
//       evt.stopPropagation();
//     } else { // IE
//       evt.cancelBubble = true;
//       evt.returnValue = false;
//     }
//     return false;
//   }
//   window.addEventListener('mousewheel', scrollHandler, {passive: false})
//   onUnmounted(() => {
//     window.removeEventListener('mousewheel', scrollHandler)
//   })
// }
