
import {computed, defineComponent, onBeforeUnmount, provide, Ref, ref} from "vue";
import * as SiteUtil from "@/officialSite/views/SiteUtil";
import {Langs} from "@/officialSite/utils/langUtil";
import {useStore} from "vuex";
import {StoreOption} from "@/officialSite/store";

export default defineComponent({
  setup() {
    // -------------设置判断为移动端的字段-------------
    const store = useStore<StoreOption>()
    const isMobile = ref()
    const setIsMobile = () => {
      isMobile.value = SiteUtil.isMobile()
    }
    setIsMobile()
    provide<Ref<boolean>>('IS_MOBILE', isMobile);
    // provide<Ref<Langs>>('LANG', computed<Langs>(() => store.state.currentLang));
    provide<Ref<Langs>>('LANG', computed<Langs>(() => isMobile.value ? Langs.zh : store.state.currentLang));// 移动端临时强制中文
    // provide<Ref<Langs>>('LANG', computed<Langs>(() => Langs.zh));// 临时强制中文

    window.addEventListener('resize', setIsMobile)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setIsMobile)
    })

    // -------------设置缩放参数-------------
    const setScale = () => { // 设置rem对应字体大小
      document.getElementsByTagName('html')[0].style.fontSize = SiteUtil.getScaleRate() + 'px'
    }
    setScale()
    window.addEventListener('resize', setScale)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setScale)
    })
    return {}
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
})
