import {createRouter, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'officialSite',
    component: () => import(/* webpackChunkName: "officialSite" */ '../views/index.vue'),
    // 所有子页路径均采用一级绝对路径,以缩短url长度,更利于收录。（需注意所有子页的path不重复）
    children: [
      { // 这个记录为了部署到服务器后，访问/article/xxx路由不会被认为不存在
        path: '/article/{id}',
        name: 'newsDetail',
        component: () => import('./RouterView.vue'),
      },
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
      },
      {
        path: '/experienceIntelligence', // 体验智能
        component: () => import('./RouterView.vue'),
        children: [
          {
            path: '/zhiyupro', // 知语Pro
            name: 'zhiyupro',
            component: () => import(/* webpackChunkName: "zhiyupro" */ '../views/experienceIntelligence/zhiyupro/index.vue')
          },
          {
            path: '/nice', // NICE
            name: 'nice',
            component: () => import(/* webpackChunkName: "nice" */ '../views/experienceIntelligence/nice/index.vue')
          },
          {
            path: '/solution', // 行业解决方案
            name: 'solution',
            component: () => import(/* webpackChunkName: "solution" */ '../views/experienceIntelligence/solution/index.vue')
          },
        ]
      },
      {
        path: '/experienceConsulting',  // 体验咨询
        component: () => import('./RouterView.vue'),
        children: [
          {
            path: '/user', // 用户研究
            name: 'user',
            component: () => import(/* webpackChunkName: "user" */ '../views/experienceConsulting/user/index.vue')
          },
          {
            path: '/insight', // 体验洞察
            name: 'insight',
            component: () => import(/* webpackChunkName: "insight" */ '../views/experienceConsulting/insight/index.vue')
          },
          {
            path: '/improve', // 体验改善
            name: 'improve',
            component: () => import(/* webpackChunkName: "improve" */ '../views/experienceConsulting/improve/index.vue')
          },
        ]
      },
      {
        path: '/operate', // 体验运营
        component: () => import(/* webpackChunkName: "operate" */ '../views/operate/index.vue')
      },
      {
        path: '/open', // 海王
        name: 'open',
        component: () => import(/* webpackChunkName: "open" */ '../views/open/index.vue')
      },
      {
        path: '/nlp', // nlp
        name: 'nlp',
        component: () => import(/* webpackChunkName: "nlp" */ '../views/nlp/index.vue')
      },
      {
        path: '/case', // 客户成功
        name: 'case',
        component: () => import(/* webpackChunkName: "case" */ '../views/case/index.vue')
      },
      {
        path: '/aboutUs', // 关于我们
        component: () => import('./RouterView.vue'),
        children: [
          {
            path: '/introduce', // 公司介绍
            name: 'introduce',
            component: () => import(/* webpackChunkName: "introduce" */ '../views/aboutUs/introduce/index.vue')
          },
          {
            path: '/culture', // 企业文化
            name: 'culture',
            component: () => import(/* webpackChunkName: "culture" */ '../views/aboutUs/culture/index.vue')
          },
          {
            path: '/news', // 新闻动态
            name: 'news',
            component: () => import(/* webpackChunkName: "news" */ '../views/aboutUs/news/index.vue')
          },
          // {
          //   path: '/article/:id', // 文章详情 （2022-06-10详情改为独立入口模板传参）
          //   name: 'article',
          //   component: () => import(/* webpackChunkName: "article" */ '../views/aboutUs/news/detail/index.vue')
          // },
          {
            path: '/join', // 加入我们
            name: 'join',
            component: () => import(/* webpackChunkName: "join" */ '../views/aboutUs/joinus/index.vue')
          }
        ]
      },
    ],
  },
  {
    // 匹配未定义的路由，跳转回首页(服务器需后台做fallback处理，未匹配的路由，返回入口页。本条设置才会发挥作用。如果后端路由直接做了未匹配路由条目的重定向，则本条无需配置)
    path: '/:pathMatch(.*)',
    name: 'redirectToHome',
    redirect: '/',
  }
]

const router = createRouter(<RouterOptions>{
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // 前进后退时，保持离开时的滚动位置；路由跳转时，自动回到顶部
    if (savedPosition) {
      return savedPosition
    } else {
      return {left: 0, top: 0}
    }
  }
})

export default router

// 页面静态化、SEO优化

// 打包后，dist输出目录中有多个入口html文件，分别为各个子页的静态页面，服务端需添加以下路由将各个静态子页面映射到路由

// 官网入口
// Route::get('/', function () {return view()->file(public_path().'/index.html');});
// 管理入口
// Route::get('/admin', function () {return view()->file(public_path().'/admin.html');});
// fallback处理，未知url（404），则返回官网入口
// Route::fallback(function(){return view()->file(public_path().'/index.html');});
//
// // 静态化页面页路由
// Route::get('/zhiyupro', function () {return view()->file(public_path().'/zhiyupro/index.html');});
// Route::get('/nice', function () {return view()->file(public_path().'/nice/index.html');});
// Route::get('/solution', function () {return view()->file(public_path().'/solution/index.html');});
//
// Route::get('/user', function () {return view()->file(public_path().'/user/index.html');});
// Route::get('/insight', function () {return view()->file(public_path().'/insight/index.html');});
// Route::get('/improve', function () {return view()->file(public_path().'/improve/index.html');});
//
// Route::get('/operate', function () {return view()->file(public_path().'/operate/index.html');});
// Route::get('/open', function () {return view()->file(public_path().'/open/index.html');});
// Route::get('/case', function () {return view()->file(public_path().'/case/index.html');});
//
// Route::get('/introduce', function () {return view()->file(public_path().'/introduce/index.html');});
// Route::get('/culture', function () {return view()->file(public_path().'/culture/index.html');});
// Route::get('/news', function () {return view()->file(public_path().'/news/index.html');});
// Route::get('/join', function () {return view()->file(public_path().'/join/index.html');});
//
// Route::get('/nlp', function () {return view()->file(public_path().'/nlp/index.html');});
// // 新闻入口，使用模板嵌入数据
// Route::get('/article/{id}', 'Home\\Article\\ArticleShowController@getArticleDetailForTemplate');


// // sitemap
// Route::get('/sitemap.xml', 'SitemapController@getSitemap');
// // robots
// Route::get('/robots.txt', function () {return view()->file(public_path().'/robots.txt');});
