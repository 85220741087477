import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import './utils/normalize.less'
// import './utils/webgl-utils'
import './utils/banner'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MetaInfo from 'vue-meta-info'
import 'element-plus/es/components/message/style/css'
import ElementPlus from 'element-plus'

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(MetaInfo)
  .mount('#app')

