console.info(`
  __  __            _____           _       _     _   
 |  \\/  |          |_   _|         (_)     | |   | |  
 | \\  / | __ ___  __ | |  _ __  ___ _  __ _| |__ | |_ 
 | |\\/| |/ _\` \\ \\/ / | | | '_ \\/ __| |/ _\` | '_ \\| __|
 | |  | | (_| |>  < _| |_| | | \\__ \\ | (_| | | | | |_ 
 |_|  |_|\\__,_/_/\\_\\_____|_| |_|___/_|\\__, |_| |_|\\__|
                                       __/ |          
                                      |___/           
`)
